._6v_P0a_bmuiScope {
  white-space: pre-wrap;
}

._6v_P0a_bmuiScope .MuiOutlinedInput-notchedOutline {
  border-color: var(--input-outline-color);
}

._6v_P0a_bmuiScope .MuiOutlinedInput-root:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--input-outline-hover-color);
}

._6v_P0a_bmuiScope .MuiOutlinedInput-root:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary);
}

._6v_P0a_bmuiScope .MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}

._6v_P0a_bmuiScope .MuiRadio-colorPrimary {
  color: var(--primary);
}

._6v_P0a_bmuiScope .MuiSwitch-colorSecondary.Mui-checked {
  color: var(--primary);
}

._6v_P0a_bmuiScope .MuiCheckbox-colorSecondary:hover {
  color: var(--primary);
}

._6v_P0a_bmuiScope .MuiCheckbox-root.Mui-checked {
  color: var(--primary);
}

._6v_P0a_bmuiScope .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--primary);
}

._6v_P0a_bmuiScope .MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: var(--primary-glow);
}

._6v_P0a_bmuiScope .MuiCheckbox-root.MuiIconButton-colorSecondary:hover {
  background-color: rgba(20, 100, 130, .04);
}

._6v_P0a_bmuiScope .MuiCheckbox-root.Mui-disabled {
  color: rgba(0, 0, 0, .26);
}

._6v_P0a_bmuiScope input[readonly] {
  background: rgba(204, 204, 204, .3);
}

._6v_P0a_clickthroughPopover {
  pointer-events: none;
}

._6v_P0a_clickthroughPopover .MuiPopover-paper {
  pointer-events: auto;
}

/*# sourceMappingURL=Layout.f976b0ab.css.map */
